import HintSectionAnalysis from "./HintSectionAnalysis";

class HintSectionAnalyser {
    static analyseHintSection = (section, fields) => {
        const analysis = new HintSectionAnalysis();

        if (section.isAssociatedWithHint) {
            analysis.setSectionHint(section.hint);
        }

        const collectFieldHints = fields => fields.forEach(field => {
            if (field.isAssociatedWithHint) {
                analysis.addFieldHint(field.hint);
            }

            if (field.type === 'resource') {
                field.field.resourceRenders.forEach(resourceRender => {
                    collectFieldHints(resourceRender.fields); // collect pr. rendered resource
                });
            }

            const subFields = field.field;
            if (Array.isArray(subFields)) {
                collectFieldHints(subFields);
            }
        });

        collectFieldHints(fields);

        return analysis;
    };
}

export default HintSectionAnalyser;
